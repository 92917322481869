
import {
  Component, Prop,
} from 'vue-property-decorator';
import { getComponent, getView, sleep } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import { mixins } from 'vue-class-component';
import GenericMixin from '@/lib/mixins/GenericMixin';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    FormAddFieldPopup: () => getView('FormAddFieldPopup'),
    FormFieldDetails: () => getView('FormFieldDetails'),
    FormFieldPreview: () => getView('FormFieldPreview'),
    DateRangePicker,
    draggable,
  },
})
export default class FormDetailPopup extends mixins(GenericMixin) {
  @Prop({ type: Object, required: false }) form?: any;

  @Prop({ type: Array, required: false }) formCategories?: any;

  @Prop({ type: Array, required: false }) formArtificialLiftTypes?: any;

  @Prop({ type: Array, required: false, default: [] }) formTitles: any;

  windowWidth = window.innerWidth;

  activeTableID: any = null;

  get dateOfDisplayOptions() {
    // console.log(this.showAddFieldPopup);
    // console.log(this.dragEnding);
    let options: any = [{ id: 0, title: 'Created date' }];

    if (this.localFormFields) {
      const fieldOptions = this.localFormFields
        .filter((f) => f.category === 'Date/Time')
        .map((i) => ({
          // @ts-ignore
          id: i.id,
          title: i.title,
        }));

      options = options.concat(fieldOptions);
    }
    return options;
  }

  showAddFieldPopup = false;

  customSignalRange: any = { startDate: null, endDate: null };

  actionCategory = 'None';

  previewMode = false;

  savingInputValueChange: any = false;

  showSavingDetailsBannerID: any = false;

  savingDetailsBannerIDText: any = false;

  hideDateOfDisplay = false;

  localFormFields: any = [];

  created() {
    if (this.form.fields) {
      this.localFormFields = JSON.parse(JSON.stringify(this.form.fields));
    }
  }

  selectDateOfDisplay(option) {
    this.hideDateOfDisplay = true;
    this.form.dateOfDisplay = option;
    this.$nextTick(() => {
      this.hideDateOfDisplay = false;

      this.$forceUpdate();
    });
  }

  alreadyOpenFieldMsg(title) {
    this.setSavingDetailsBannerID(`Please complete ${title} form before moving on`);
    this.removeSavingDetailsBannerID(3000);
  }

  setSavingDetailsBannerID(text) {
    this.savingInputValueChange = true;
    this.showSavingDetailsBannerID = true;
    this.setSavingDetailsBannerIDText(text);
  }

  setSavingDetailsBannerIDText(text) {
    this.savingDetailsBannerIDText = text;
  }

  async removeSavingDetailsBannerID(wait = 5000, text = '') {
    this.savingInputValueChange = false;
    if (text !== '') {
      this.setSavingDetailsBannerIDText(text);
    }
    await sleep(wait);
    this.showSavingDetailsBannerID = false;
    this.savingDetailsBannerIDText = '';
  }

  saveField(field) {
    this.form.fields.push(field);
    this.localFormFields.push(field);
    this.showAddFieldPopup = false;
    this.$nextTick(() => {
      this.$forceUpdate();
      // console.log(this.dateOfDisplayOptions);
    });
  }

  removeFormField(title) {
    this.form.fields = this.form.fields.filter((field) => field.title !== title);
    this.freshForm();
  }

  showNewFieldPopup() {
    const openField = this.form.fields.find((f) => f.showDetails);
    if (openField) {
      this.alreadyOpenFieldMsg(openField.title);

      // this.$emit('already-open-field-msg', openField.title);
    } else {
      this.showAddFieldPopup = true;
    }
  }

  async saveForm() {
    const openField = this.form.fields.find((f) => f.showDetails);
    if (openField) {
      this.alreadyOpenFieldMsg(openField.title);
      return;
      // this.$emit('already-open-field-msg', openField.title);
    }

    const errorsExist = this.checkErrorsForNext();
    if (!errorsExist) {
      this.dataLoading = true;
      if (this.form.id) {
        await metaDataModule.putActionBuilder(this.form);
      } else {
        await metaDataModule.postActionBuilder(this.form);
      }
      this.dataLoading = false;
      this.$emit('save-popup');
    }
  }

  freshForm() {
    this.dragEnding = true;
    this.$nextTick(() => {
      this.dragEnding = false;

      this.$forceUpdate();
    });
  }

  //  validateFields() {

  //    const errors: any = [];
  //    this.form.fields.forEach((field) => {
  //      const errorExist = this.$refs[field.id][0].validateField();
  //      if (errorExist) {
  //        errors.push(errorExist);
  //      }
  //    });

  //    if (errors && errors.length) {
  //      console.log('validation failed');
  //    }
  //  }

  formName: any = [];

  dataLoadingBackend = false;

  activeWorkspaceID: any = null;

  dropdownArtificialLiftTypesLocation: any = [];

  dropdownSymptomsLocation: any = [];

  dropdownCausesLocation: any = [];

  isHoveringIndex = -1;

  errors: any = [];

  deleteGroupPopup = false;

  actionName: any = '';

  availableSympotoms: any = [];

  availableSystems: any = [];

  dropdownSymptomsSelected: any = [];

  dropdownCausesSelected: any = [];

  availableCauses: any = [];

  friendlyLayerName = '';

  deleteGroupParams: any = {};

  availableSearchOptions: any = [];

  newAttributePayload: any = [];

  get getDisableForNext() {
    //  console.log(this.actionName);
    if (this.form.title === '') {
      return true;
    }
    if (this.form.fields && this.form.fields.length < 1) {
      return true;
    }

    this.errors = [];

    return false;
  }

  updateFormField(fieldPayload) {
    this.dragEnding = true;
    const { id, data } = fieldPayload;
    const index = this.form.fields.findIndex((field) => field.id === id);
    this.form.fields[index] = data;
    this.$nextTick(() => {
      this.dragEnding = false;

      this.$forceUpdate();
    });
  }

  checkErrorsForNext() {
    this.errors = [];
    //  console.log(this.actionName);
    if (this.form.title === '') {
      this.errors.push('formTitle');
      return true;
    }

    const { title } = this.form;
    if (this.form.title && this.formTitles.includes(title.toLowerCase())) {
      this.errors.push('formTitleExist');
      return true;
    }
    if (this.form.fields.length < 1) {
      this.errors.push('formFields');
      return true;
    }

    this.errors = [];
    return false;
  }

  stepNo: any = 0;

  newColumnName = '';

  showModal = false;

  showColumnMenu = false;

  selectedTags: any = [];

  availableTags: any = [];

  showWellMenu = false;

  dataLoading = false;

  foundSearchResult = false;

  selectedAttribute: any = {};

  context: any = null;

  newWellName = '';

  frameworkComponents: any = {};

  showSearchPopup = false;

  newCloumnType = '';

  knowAttributesData: any = [];

  dropdownLocation: any = [];

  didSelectDropdown(e, columnName) {
    // if (type == 'Status Changes' || type == 'Duplicate Tasq') {
    // eslint-disable-next-line no-console
    console.log(columnName);
    const rect = e.srcElement.parentElement.getBoundingClientRect();
    this.dropdownLocation = [rect.x, rect.y + 55];
  }

  didSelectSystemsDropdown(e) {
    const rect = e.srcElement.parentElement.getBoundingClientRect();
    this.dropdownArtificialLiftTypesLocation = [rect.x, rect.y + 50];
  }

  hideArtificialLiftsDropdown() {
    //  this.actionPayload.system = (this.form.artificialLiftTypes);
    this.dropdownArtificialLiftTypesLocation = [];
  }

  didArtificalLiftsSelectDropdownOption(e) {
    const rect = e.srcElement.parentElement.getBoundingClientRect();
    this.dropdownArtificialLiftTypesLocation = [rect.x, rect.y + 55];

    //  this.dropdownLocation = [];
  }

  didSymptomsSelectDropdownOption(e) {
    const rect = e.srcElement.parentElement.getBoundingClientRect();
    this.dropdownSymptomsLocation = [rect.x + 195, rect.y - 175];

    //  this.dropdownLocation = [];
  }

  didSelectSymptomsDropdown(e) {
    const rect = e.srcElement.parentElement.getBoundingClientRect();
    this.dropdownSymptomsLocation = [rect.x + 190, rect.y];
  }

  hideSymptomsDropdown() {
    //  this.actionPayload.sympotoms = (this.dropdownSymptomsSelected);
    this.dropdownSymptomsLocation = [];
  }

  async didCausesSelectDropdownOption() {
    //  this.dropdownLocation = [];
  }

  didSelectCausesDropdown(e) {
    const rect = e.srcElement.parentElement.getBoundingClientRect();
    this.dropdownCausesLocation = [rect.x + 190, rect.y - 80];
  }

  hideCausesDropdown() {
    //  this.actionPayload.causes = (this.dropdownCausesSelected);
    this.dropdownCausesLocation = [];
  }

  didHoverChecklist(index) {
    this.isHoveringIndex = index;
  }

  didLeaveHoverChecklist(index) {
    // eslint-disable-next-line no-console
    console.log(index);
    this.isHoveringIndex = -1;
  }

  dragEnding = false;

  onEnd() {
    this.dragEnding = true;
    //  console.log(e.oldIndex);
    //  console.log(e.newIndex);
    //  const f = this.form.fields;
    // this.form.fields =  this.array_move(f, e.oldIndex, e.newIndex)
    //  console.log(this.array_move(f, e.oldIndex, e.newIndex));
    this.$nextTick(() => {
      this.dragEnding = false;
    });
  }

  checkMoveChecklist(event) {
    // console.log(event);
    const { draggedContext } = event;
    if (draggedContext.element) {
      //  console.log(draggedContext.element);
      //  const index = (this.form.fields?.findIndex((input) => input.id === draggedContext.element.id));
      //  console.log(index);
    }
    // this.activeMoveTasqID = (draggedContext.element.id);
  }

  async didSelectDropdownOption() {
    //  this.dataLoading = true;
    this.dropdownLocation = [];
  }

  hideDropdown() {
    this.dropdownLocation = [];
  }

  selectAttribute(attribute) {
    this.selectedAttribute = attribute;
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
  }

  formatBubbleText(val) {
    return this.toTitleCase(val.toLowerCase());
  }

  async closeDialog() {
    this.showModal = false;
  }

  onResize() {
    this.windowWidth = window.innerWidth;
  }
}
